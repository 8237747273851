import React, {  useState } from 'react'
import { Link } from 'react-router-dom'
import notifyService from '../../api/notifySerivce';
import { Controller, useForm } from 'react-hook-form';
import { Button, FormHelperText, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useCallback } from "react";
import useRazorpay from "react-razorpay";
import apiService from '../../api/apiService';
import useDidMountEffect from '../../hooks/useDidMountEffect';
const Homepage = () => {
  useDidMountEffect(() => {
    registerApi()
    // registerApiList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

  const {
    handleSubmit,
    control,
    getValues, reset,
    formState: { errors },
  } = useForm({});

  const validatePhoneNumber = (value) => {
    if (value.length) {
      if (value.length !== 10) {
        return 'Phone number must be 10 digits';
      }
      const matches = value.match(
        /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
      );
      if (matches === null) {
        return "Only numbers allowed";
      }
    }
  };
  const validateEmail = (value) => {
    if (value !== '') {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const isValid = emailRegex.test(value);
        return isValid || 'Invalid email address';
    }

};
const [count,setCount] = useState()
const registerApi = () =>{
  apiService('register/count', '', 'get').then((res) => {
   
    if (res) {
      debugger
        if (res.data) {
          debugger
            let count = 40 - res.data
            debugger
            setCount(count);
            debugger
        }
        else{
          let count = 40 
          debugger
          setCount(count);
        }
    }
})
}
// const registerApiList = () =>{
//   apiService('register/list', '', 'get').then((res) => {
   
//     if (res) {
//       debugger
       
//     }
// })
// }

  const [loading, setLoading] = useState(false)
  const onSubmit = (data) => {
    handlePayment(data)
    // saveApi(data)
  }
  const [Razorpay] = useRazorpay();
  const handlePayment = useCallback(async (data) => {
    // const order = await createOrder(params);
    const options = {
      key: "rzp_live_tgAZPi1jJivL9S",
      amount: "118000",
      currency: "INR",
      name: "Vastu Vedic Trust",
      description: "Introduction to Temple Architecture",
      // image: "https://s3.ap-south-1.amazonaws.com/www.varuvaai.com/assets/images/logo-fav.png",
      // order_id: order.id,
      handler: (res) => {
        console.log(res);
        saveApi(res)
      },
      prefill: {
        name: data.name,
        email: data.email,
        contact: data.phone,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzpay = new Razorpay(options);
    rzpay.on("payment.failed", function (response) {
      notifyService(
        "danger",
        "Payment Failed",
        response.error.reason
      );
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });
    rzpay.open();
  }, [Razorpay]);
  const saveApi = (res) => {
    var getData = getValues()
    getData.invoice = res.razorpay_payment_id
    // getData.invoice = "pay_No7pZSLIhJOOiT"
    // debugger
    apiService('register', getData, 'post').then((result) => {
      console.log(result)
      if (result) {
        if (result.data) {
          notifyService("success", result.data);
          handleReset()
          registerApi()
        }
      }
    }).catch((err) => {
      console.log(err)
    });
  }
  const onError = (errors, e) => {
    notifyService(
      "danger",
      "Form is invalid",
      "Please check the mandatory fields"
    );
  };
  const handleScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      // setCollapsed(true); // Collapse the navbar after clicking on a link
    }
  };
  const handleReset = () => {
    reset()
  }
  return (
    <>
      <nav className="navbar navbar-expand-lg sticky-top navbar-light bg-light ml-auto">
        <div className="nav-logo">
          <img src="./images/north.png" alt="" />
        </div>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item active">
              <Link className="nav-link" onClick={() => handleScrollTo('home')}>Home <span className="sr-only">(current)</span></Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" onClick={() => handleScrollTo('aboutus')}>About</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" onClick={() => handleScrollTo('register')}>Register</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" onClick={() => handleScrollTo('contact')}>Contact Us</Link>
            </li>
          </ul>
        </div>
      </nav>
      <section className="over" id="home">
        <div className="container">
          <div className="full-div text-center">

            <div className="title">
              <h2>Vastu Vedic Research Foundation </h2>
              <h2>(Vastu Vedic Trust)</h2>
              {/* <span>Founder: Dr.V.Ganapati Sthapati</span> */}
              <h2 className="present">PRESENTS</h2>
            </div>
          </div>
          <div className="one-day">
            <h3>A ONE-DAY LECTURE ON</h3>
            <h2>Introduction to Temple Architecture</h2>
          </div>
        </div>
      </section>
      <section id="aboutus" className="about-pad">
        <div className="container">
          <div className="about-me-speck text-center">
            <h1>About The Speaker</h1>
          </div>
          <div className="row">
            {/* <div className="col-lg-6 text-center">
              <div className="moorthi">
                <img src="./images/moorthy1.webp" alt="" />
              </div>
            </div> */}
            <div className="col-lg-12 text-center">
              <div className="name">
                <h2>Dr.K.DAKSHINAMOORTHY STHAPTI,PH.D</h2>
                <div className="name-content">
                  <span>Heriditary Indian Traditional Architect & Sculptor</span><br />
                  <span>Sthapti,HR & CE State Heritage Committee, T.N</span>
                </div>
              </div>
            </div>

          </div>
        </div>

      </section >
      <section className="section_venue" id="register">
        <div className="about-me2 text-center">
          <h1>Register</h1>
        </div>
       
        <div className="venue">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="price">
                  <h2 className='mb-3'>Our Venue</h2>

                  <h5>Contribution - Rs.1180</h5>
                  <h5>Seats Available - <span className='sets'>Only Few Seats Available</span> </h5>
                  <h5><i className="fas fa-calendar-alt"></i> 6th April 2024</h5>
                  <h5><i className="fas fa-clock"></i> 10am - 5pm</h5>
                  <h5 className='mobile-res'><i className="fas fa-thumbtack"></i> TK Kundram Rd,
                    Vadakadampadi,Mamallapuram 603104</h5>
                </div>
              </div>
              <div className='col-lg-6 col-md-6'>
              {/* {
          count !== 0 ? (
            
          ):( 
            <div className='text-center reg-img'>
<img src="./images/closedd.png" alt="" className='img-fluid ' />
<h2>Sorry Registration Will Be Closed</h2>
              </div>
          )
        } */}
<form
            className="signin-form"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <h2 className='mb-3'>Register Now</h2>
            <div className='form-group mb-3'>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{
                  required: `Name is required`,
                }}
                render={({ field }) => (
                  <TextField
                    placeholder={`Enter your name`}
                    fullWidth
                    type="text"
                    label="Name *"
                    {...field}
                  />
                )}
              />
              <FormHelperText className="text-danger">
                {errors.name &&
                  errors.name.message}
              </FormHelperText>
            </div>
            <div className='form-group mb-3'>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: `Email is required`, validate: validateEmail 
                }}
                render={({ field }) => (
                  <TextField
                    placeholder={`Enter your email`}
                    fullWidth
                    label="Email ID *"
                    type="text"
                    {...field}
                  />
                )}
              />
              <FormHelperText className="text-danger">
                {errors.email &&
                  errors.email.message}
              </FormHelperText>
            </div>
            <div className='form-group mb-3'>
              <Controller
                name="phone"
                control={control}
                defaultValue=""
                rules={{ required: 'Phone Number is required', validate: validatePhoneNumber }}
                render={({ field }) => (
                  <TextField
                    placeholder={`Enter your Phone Number`}
                    fullWidth
                    type="number"
                    label="Phone Number *"
                    {...field}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                  }}
                  />
                )}
              />
              <FormHelperText className="text-danger">
                {errors.phone &&
                  errors.phone.message}
              </FormHelperText>
            </div>
            <div className="mt-5 mb-4 text-center">
              <Button
                variant="contained"
                color="error"
                className="form-btn ml-3 "
                onClick={handleReset}
              >
                Reset
              </Button>
              <LoadingButton
                loadingPosition="start"
                type="submit"
                loading={loading}
                variant="contained"
                color="success"
                className="form-btn ml-3 "
              >
                <span>Register Now</span>
              </LoadingButton>
            </div>
          </form>
               
              </div>
            </div>
          </div>
        </div>

      </section>
      <section className="bg-color" id="contact">
        <div className="container">
          <div className="about-me text-center">
            <h1>Contact Us</h1>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-12">
              <div className="box1 text-center">
                <span className=""><i className="fas fa-phone-alt"></i></span>
                <h5>+91 9943949888</h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <div className="box1 text-center">
                <span className=""><i className="fas fa-globe"></i></span>
                <h5>www.vastuved.com</h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <div className="box1 text-center">
                <span className=""><i className="fas fa-envelope"></i></span>
                <h5>vaastuved@gmail.com</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="footer-bg">
          <p className="m-0">Vastu Vedic Research Foundation ©2024 All rights reserved</p>
        </div>
      </section>
    </>
  )
}

export default Homepage