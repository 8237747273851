import React, { useState } from 'react'
import useDidMountEffect from '../hooks/useDidMountEffect';
import apiService from '../api/apiService';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
const Registertable = () => {
    const [store,setStore]=useState()
    useDidMountEffect(() => {
        registerApiList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const registerApiList = () =>{
        apiService('register/list', '', 'get').then((res) => {
         debugger
         setStore(res.data)
      })
      }
  return (
    <div>

<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>S.No</TableCell>
            <TableCell>Name</TableCell>
            <TableCell >Email</TableCell>
            <TableCell >Phone</TableCell>
            <TableCell >Registration Number</TableCell>
            <TableCell >Invoice</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {store && store.map((row,i) => (
            <TableRow
              key={i}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
               <TableCell align="left">{`${i + 1}`}</TableCell>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">{row.email}</TableCell>
              <TableCell align="left">{row.phone}</TableCell>
              <TableCell align="left">{row.registrationNumber}</TableCell>
              <TableCell align="left">{row.invoice}</TableCell>
              
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  )
}

export default Registertable
